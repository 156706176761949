import { useState, useEffect } from "react";
import {
  Grid,
  Chip,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  IconButton,
  Typography,
  TextField,
  Button,
  Switch,
  InputAdornment,
  Box,
  Checkbox,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Snackbar,
  Modal,
  Fade,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore, Close } from "@material-ui/icons";
import Categories from "../../components/categories/categories";
import { useHistory } from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import {
  userDetails,
  createQuiz,
  getCategories,
  resetQuiz,
  getOverallQuizHistory,
  getOverallStudentQuizHistory,
  resetCreateQuiz,
  getUserActivity,
  getActivePlans,
  setUserActivity,
  getQuesCount,
  resetQuesCount,
  createTutorFeedbackBlock,
  resetCreateTutorFeedbackBlock,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import UserAgreement from '../../pages/user-agreement/user-agreement';
import Menu from "../../pages/menu/menu";
import "./create-quiz-page.scss";

const allDiscText = "All Discipline";
const allOrgText = "All Organ System";
const allExamTypeText = "All Exam Type";
const min = 1;
const defaultQuesLen = 10;

export default function CreateQuizPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [quizProgressId, setQuizProgressId] = useState();
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);
  const [selectedOrgSystem, setSelectedOrgSystem] = useState([]);
  const [examType, setExamType] = useState([]);
  const [selectedExamType, setSelectedExamType] = useState([]);
  const [totalOrgSystem, setTotalOrgSystem] = useState([]);
  const [totalDiscipline, setTotalDiscipline] = useState([]);
  const [questionOptions, setQuestionOptions] = useState({
    unused: true,
    correct: false,
    incorrect: false,
    marked: false
  });
  const [timerMode, setTimerMode] = useState(true);
  const [tutorMode, setTutorMode] = useState(true);
  const [questionLength, setQuestionLength] = useState(defaultQuesLen);
  // const [quizLimit, setQuizLimit] = useState(10);
  const [selectedQuesTypeLength, setSelectedQuesTypeLength] = useState(true);
  const [max, setMax] = useState(40);
  const [plan, setPlan] = useState({});
  const [quizErr, setQuizErr] = useState(false);
  const [isTutor, setIsTutor] = useState(false);
  const [isTutorConfirmModal, setIsTutorConfirmModal] = useState(false);
  const [quizErrMsg, setQuizErrMsg] = useState('');
  const [userRole, setUserRole] = useState('');
  const [quesCountData, setQuesCountData] = useState({});
  const [isQuestionLimitModalOpen, setIsQuestionLimitModalOpen] = useState(false);
  const [quizName, setQuizName] = useState(
    `Quiz-${new Date().toLocaleString()}`
  );
  // const [open, setOpen] = useState(false);
  const [subDateOpen, setSubDateOpen] = useState(false);
  const [openAgreementModal, setOpenAgreementModal] = useState(false);
  const [typeCount, setTypeCount] = useState({
    unused: 0,
    correct: 0,
    incorrect: 0,
    marked: 0
  });
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state.loggedInUser?.user;
  });

  const userDetailsFromToken = useSelector((state) => {
    return state.userDetailsFromToken?.userDetails;
  });

  const categoriesData = useSelector((state) => {
    return state.categoriesList;
  });

  const quesCountDataRes = useSelector((state) => {
    return state.quesCount?.quesCountDtls;
  });

  const userName = useSelector((state) => {
    return state.studentDetails?.userDetails?.displayName;
  });

  const createQuizReq = useSelector((state) => {
    return state.createQuiz;
  });

  const overallStudentQuiz = useSelector((state) => {
    return state.overallStudentQuiz?.overallStudentQuiz;
  });

  const activePlans = useSelector((state) => {
    return state.getActivePlans;
  });

  const createFeedbackBlock = useSelector((state) => {
    return state.createTutorFeedbackBlock?.createBlock;
  });

  const userPreferenceData = useSelector((state) => {
    return state.getUserPreference?.preference;
  });

  const getCategoryMap = () => {
    const categoryMap = {
      discipline: [],
      organSystem: [],
      examType: []
    };

    for (let i = 0; i < selectedDiscipline.length; i++) {
      categoryMap.discipline.push(selectedDiscipline[i]);
    }

    for (let i = 0; i < selectedOrgSystem.length; i++) {
      categoryMap.organSystem.push(selectedOrgSystem[i]);
    }

    for (let i = 0; i < selectedExamType.length; i++) {
      categoryMap.examType.push(selectedExamType[i]);
    }

    return categoryMap;
  }

  const onCreateQuiz = (isConfirm = false) => {
    if (isTutor && tutorMode && timerMode && !isConfirm) {
      setIsTutorConfirmModal(true);
      return;
    }

    const savedUserId = window.localStorage.getItem("userId");

    const param = {
      name: quizName.trim(),
      userId: savedUserId,
      categoryMap: getCategoryMap(),
      quizOptions: {
        tutorOn: isTutor ? tutorMode : false,
        timerOn: timerMode
      },
      noOfQuestions: questionLength,
      questionOptions,
    };

    dispatch(createQuiz(param));

    dispatch(setUserActivity({
      userId: savedUserId,
      quizAccessCount: 1,
      questionAttemptCount: questionLength
    }));
  };

  const onDisciplineCatSelect = (quesArr, subcategory, isChecked) => {
    setSelectedDiscipline([...quesArr]);
  };

  const onOrgSysCatSelect = (quesArr, subcategory, isChecked) => {
    setSelectedOrgSystem([...quesArr]);
  };

  const onExamTypeCatSelect = (quesArr, subcategory, isChecked) => {
    setSelectedExamType([...quesArr]);
  };

  useEffect(() => {
    if (userPreferenceData && !userPreferenceData?.userAcknowledged) {
      const role = window.localStorage.getItem("role");
      if (role !== 'ROLE_ADMIN') {
        setOpenAgreementModal(true);
      }
    }
  }, [userPreferenceData]);

  useEffect(() => {
    if (userDetailsFromToken) {
      window.localStorage.setItem("userId", userDetailsFromToken.id);
      window.localStorage.setItem("role", userDetailsFromToken.primaryRole);

      if (userDetailsFromToken.primaryRole !== 'ROLE_ADMIN' && userPreferenceData && !userPreferenceData?.userAcknowledged) {
        setOpenAgreementModal(true);
        return;
      }

      setRole(userDetailsFromToken.primaryRole);

      const userData = {
        name: userDetailsFromToken?.displayName,
        email: userDetailsFromToken?.email
      };
      window.localStorage.setItem("userdetails", JSON.stringify(userData));

      if (!userName) {
        dispatch(userDetails(userDetailsFromToken.id));
      }
      dispatch(getUserActivity(userDetailsFromToken.id));
      dispatch(getActivePlans(userDetailsFromToken.id));
      // dispatch(getUserPackage(userDetailsFromToken.id));

      if (userDetailsFromToken && !userName) {
        mixpanel.track('Sign In', {
          $name: userDetailsFromToken?.displayName,
          $user_id: userDetailsFromToken?.displayName,
          email: userDetailsFromToken?.email,
          role: userDetailsFromToken?.primaryRole
        });
        mixpanel.identify(userDetailsFromToken?.displayName);
        // Set user properties
        mixpanel.people.set({
          $email: userDetailsFromToken?.displayName,
          $name: userDetailsFromToken?.email,
          $role: userDetailsFromToken?.primaryRole
        });
        mixpanel.track("Menu-create-quiz", {
          $name: userDetailsFromToken?.displayName,
          email: userDetailsFromToken?.email,
          role: userDetailsFromToken?.primaryRole
        });
      }
    }
  }, [dispatch, userDetailsFromToken, userName, userPreferenceData]);

  useEffect(() => {
    if (createFeedbackBlock?.blockId) {
      window.localStorage.setItem("feedbackBlockId", createFeedbackBlock?.blockId);
      dispatch(resetCreateTutorFeedbackBlock());
      history.push(`exam/${createFeedbackBlock?.quizId}/1/tutor/1`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFeedbackBlock, createQuizReq]);

  useEffect(() => {
    if (quesCountDataRes) {
      setQuesCountData(quesCountDataRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quesCountDataRes]);

  useEffect(() => {
    const userinfos = JSON.parse(localStorage.getItem('userdetails'));
    const userId = window.localStorage.getItem("userId");
    const userrole = window.localStorage.getItem("role");

    if (createQuizReq?.createQuizRes && createQuizReq?.createQuizRes?.id) {
      if (isTutor && tutorMode) {
        dispatch(createTutorFeedbackBlock({
          quizId: createQuizReq.createQuizRes.id,
          userId
        }));
      } else {
        history.push(`exam/${createQuizReq.createQuizRes.id}/1`);
      }

      dispatch(resetCreateQuiz());
      window.analytics.track('Quiz created', {
        id: createQuizReq.createQuizRes.id
      });
      mixpanel.track("Create Quiz", {
        Questioncount: questionLength,
        $name: userinfos.name,
        email: userinfos.email,
        role: userrole
      });
    } else if (Object.keys(createQuizReq).length && !createQuizReq?.loading && !createQuizReq.createQuizReq) {
      setQuizErr(true);
      setQuizErrMsg(createQuizReq?.errMsg);
      dispatch(resetCreateQuiz());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuizReq, dispatch, history]);

  useEffect(() => {
    if (!user) {
      const savedUserId = window.localStorage.getItem("userId");
      const role = window.localStorage.getItem("role");

      if (role !== 'ROLE_ADMIN' && userPreferenceData && !userPreferenceData?.userAcknowledged) {
        setOpenAgreementModal(true);
        return;
      }

      if (savedUserId && savedUserId !== "undefined") {
        if (!userName) {
          dispatch(userDetails(savedUserId));
        }
        dispatch(getUserActivity(savedUserId));
        // dispatch(getUserPackage(savedUserId));
        dispatch(getOverallQuizHistory(savedUserId));
        dispatch(getOverallStudentQuizHistory({ userId: savedUserId, isCompleted: false }));
        populateCategories();
      }
    } else if (user && user.id) {
      window.localStorage.setItem("userId", user.id);
      window.localStorage.setItem("role", user.primaryRole);

      if (user.primaryRole !== 'ROLE_ADMIN' && userPreferenceData && !userPreferenceData?.userAcknowledged) {
        setOpenAgreementModal(true);
        return;
      }

      setRole(user.primaryRole);

      if (!userName) {
        dispatch(userDetails(user.id));
      }
      dispatch(getUserActivity(user.id));
      // dispatch(getUserPackage(user.id));
      dispatch(getOverallQuizHistory(user.id));
      dispatch(getOverallStudentQuizHistory({ userId: user.id, isCompleted: false }));
      populateCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user, userPreferenceData]);

  const setRole = (role) => {
    if (role === 'ROLE_TUTOR') {
      setUserRole('Tutor');
    } else if (role === 'ROLE_ADMIN') {
      setUserRole('Admin');
    }
  }

  useEffect(() => {
    if (overallStudentQuiz) {
      let lastProgressId = "";

      for (let i = overallStudentQuiz.length - 1; i >= 0; i--) {
        if (overallStudentQuiz[i].quizStatus === "IN_PROGRESS") {
          lastProgressId = overallStudentQuiz[i].id;
          break;
        }
      }

      setQuizProgressId(lastProgressId);
    }
  }, [overallStudentQuiz]);

  useEffect(() => {
    // if (categoriesData.loading === undefined) {
    dispatch(getCategories());
    populateCategories();
    // }

    return () => {
      setQuesCountData({});
      dispatch(resetQuesCount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (categoriesData && categoriesData.loading !== undefined) {
      setIsLoading(categoriesData.loading);
      populateCategories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesData]);

  useEffect(() => {
    if (quesCountData) {
      let questionLmt = quesCountData?.eligibleQuestionCount;

      setMax(questionLmt);

      const role = window.localStorage.getItem("role");
      if (role === 'ROLE_ADMIN' || role === 'ROLE_TUTOR') {
        setMax(40);
        questionLmt = 10;
      }

      // Show the popup when no questions left for the day
      if (!openAgreementModal && quesCountData?.questionLeftInDay <= 0 && !(role === 'ROLE_ADMIN' || role === 'ROLE_TUTOR')) {
        setIsQuestionLimitModalOpen(true);
      } else {
        setIsQuestionLimitModalOpen(false);
      }

      setRole(role);

      if (role === 'ROLE_TUTOR') {
        setIsTutor(true);
        setTimerMode(false);
      }

      const hasNotified = window.localStorage.getItem('hasNotified');

      if (!hasNotified && questionLmt && !openAgreementModal) {
        window.localStorage.setItem('hasNotified', true);
        setSubDateOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quesCountData]);

  useEffect(() => {
    dispatch(resetQuiz());
    const savedUserId = window.localStorage.getItem("userId");

    if ((user || userDetailsFromToken || savedUserId) &&
      (selectedDiscipline?.length || selectedOrgSystem?.length || selectedExamType?.length) && selectedQuesTypeLength) {
      getQuestionCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userDetailsFromToken, selectedDiscipline, selectedOrgSystem, selectedExamType, questionOptions]);

  useEffect(() => {
    const savedUserId = window.localStorage.getItem("userId");

    if (savedUserId && activePlans?.loading === undefined) {
      dispatch(getActivePlans(savedUserId));
    } else if (activePlans?.plansList) {
      let activePkg;

      if (activePlans?.plansList?.length) {
        activePkg = activePlans?.plansList?.filter(item => item.iselected)[0];
      }

      setPlan(activePkg || {});
    }
  }, [activePlans, dispatch]);

  const getQuestionCount = (categoryMapData) => {
    const params = {
      userId: window.localStorage.getItem("userId"),
      categoryMap: categoryMapData || getCategoryMap(),
      questionOptions
    };

    dispatch(getQuesCount(params));
  }

  const populateCategories = () => {
    if (
      categoriesData?.categoriesList?.discipline?.length
    ) {
      const totalDisciplineList = categoriesData?.categoriesList?.discipline;
      const totalOrgSystemList = categoriesData?.categoriesList?.organSystem;
      const examTypeList = categoriesData?.categoriesList?.examType;

      setSelectedDiscipline([...totalDisciplineList]);
      setSelectedOrgSystem([...totalOrgSystemList]);
      setSelectedExamType([...examTypeList]);
      setTotalDiscipline([...totalDisciplineList]);
      setTotalOrgSystem([...totalOrgSystemList]);
      setExamType([...examTypeList]);
    }
  };

  const handleDeleteAllCategory = (type) => {
    if (type === "discipline") {
      setSelectedDiscipline([]);
    } else if (type === "organSystem") {
      setSelectedOrgSystem([]);
    } else {
      setSelectedExamType([]);
    }
  };

  const clearSelection = (event, type) => {
    event.stopPropagation();

    if (type === "discipline") {
      if (selectedDiscipline.length) {
        setSelectedDiscipline([]);
      } else {
        setSelectedDiscipline([...totalDiscipline]);
      }
    } else if (type === "organSystem") {
      if (selectedOrgSystem.length) {
        setSelectedOrgSystem([]);
      } else {
        setSelectedOrgSystem([...totalOrgSystem]);
      }
    } else {
      if (selectedExamType.length) {
        setSelectedExamType([]);
      } else {
        setSelectedExamType([...examType]);
      }
    }
  };

  const handleDeleteCategory = (type, item) => {
    const targetCategory =
      type === "discipline" ? selectedDiscipline : type === 'organSystem' ? selectedOrgSystem :
        selectedExamType;

    for (let i = 0; i < targetCategory.length; i++) {
      if (targetCategory[i] === item) {
        targetCategory.splice(i, 1);
        break;
      }
    }

    if (type === "discipline") {
      setSelectedDiscipline([...targetCategory]);
    } else if (type === "organSystem") {
      setSelectedOrgSystem([...targetCategory]);
    } else {
      setSelectedExamType([...targetCategory]);
    }
  };

  const toggleTimerMode = (evt) => {
    const newTimerMode = evt.target.checked;
    setTimerMode(newTimerMode);
  };

  const toggleTutorMode = (evt) => {
    const newTutorMode = evt.target.checked;
    setTutorMode(newTutorMode);
  };

  const changeQuizName = (evt) => {
    const value = evt.target.value;
    setQuizName(value);
  };

  const changeQuestionsLength = (event) => {
    let value = event.target.value.trim();
    value = value ? parseInt(value) : "";

    if (value !== "" && (value > max || value < min)) {
      return;
    }

    setQuestionLength(value);
  };

  const selectQuestionType = (evt, type, countObj) => {
    const questionOptionsObj = { ...questionOptions };

    if (evt && type) {
      questionOptionsObj[type] = evt.target.checked;

      if (!questionOptionsObj?.incorrect && !questionOptionsObj?.correct && questionOptionsObj?.marked) {
        questionOptionsObj.marked = false;
      }

      setQuestionOptions(questionOptionsObj);
    } else {
      setTypeCount({
        unused: countObj.unused || 0,
        correct: countObj.correct || 0,
        incorrect: countObj.incorrect || 0,
        marked: countObj.marked || 0
      });
    }

    let isTypeSelected = false;
    Object.keys(questionOptionsObj).forEach(key => {
      if (questionOptionsObj[key]) {
        isTypeSelected = true;
      }
    });

    setSelectedQuesTypeLength(isTypeSelected);

    if (!isTypeSelected) {
      setQuesCountData({});
      setQuestionLength(0);
    }
  };

  const onResumeQuiz = () => {
    if (isTutor && tutorMode) {
      history.push(`exam/${quizProgressId}/1/tutor/1`);
    } else {
      history.push(`exam/${quizProgressId}/1`);
    }
  };

  // const isAdminUser = () => {
  //   const savedUserId = window.localStorage.getItem("userId");
  //   return savedUserId === 'ROLE_ADMIN' || savedUserId === 'ROLE_TUTOR';
  // };

  const renderQuizOptionSelections = () => {
    return (
      <>
        {/**Quiz options */}
        <Accordion defaultExpanded className="quiz-selections">
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={`header ${`header ${classes.heading}`}`}>Discipline</Typography>
            <Button
              disableElevation
              className={classes.clearSelection}
              color="primary"
              onClick={(evt) => clearSelection(evt, "discipline")}
            >
              {selectedDiscipline.length > 0 && <>Clear All</>}
              {!selectedDiscipline.length && <>Add All</>}
            </Button>
          </AccordionSummary>
          <AccordionDetails className="accordian-summary">
            {selectedDiscipline.length === totalDiscipline.length && (
              <Chip
                className="category-chip"
                label={allDiscText}
                onDelete={() => handleDeleteAllCategory("discipline")}
                color="primary"
              />
            )}

            {selectedDiscipline.length !== totalDiscipline.length &&
              selectedDiscipline?.sort((a, b) => a.localeCompare(b))?.map((item, index) => (
                <Chip
                  key={index}
                  className="category-chip"
                  label={item}
                  onDelete={() => handleDeleteCategory("discipline", item)}
                  color="primary"
                />
              ))}

            {!selectedDiscipline.length && (
              <Typography className="no-select">
                Select at least one option
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="quiz-selections">
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={`header ${classes.heading}`}>Organ System</Typography>
            <Button
              disableElevation
              className={classes.clearSelection}
              color="primary"
              onClick={(evt) => clearSelection(evt, "organSystem")}
            >
              {selectedOrgSystem.length > 0 && <>Clear All</>}
              {!selectedOrgSystem.length && <>Add All</>}
            </Button>
          </AccordionSummary>
          <AccordionDetails className="accordian-summary">
            {selectedOrgSystem.length === totalOrgSystem.length && (
              <Chip
                className="category-chip"
                label={allOrgText}
                onDelete={() => handleDeleteAllCategory("organSystem")}
                color="primary"
              />
            )}

            {selectedOrgSystem.length !== totalOrgSystem.length &&
              selectedOrgSystem?.sort((a, b) => a.localeCompare(b))?.map((item, index) => (
                <Chip
                  key={index}
                  className="category-chip"
                  label={item}
                  onDelete={() => handleDeleteCategory("organSystem", item)}
                  color="primary"
                />
              ))}

            {!selectedOrgSystem.length && (
              <Typography className="no-select">
                Select at least one option
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="quiz-selections">
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={`header ${classes.heading}`}>Exam Type</Typography>
            <Button
              disableElevation
              className={classes.clearSelection}
              color="primary"
              onClick={(evt) => clearSelection(evt, "examType")}
            >
              {selectedExamType.length > 0 && <>Clear All</>}
              {!selectedExamType.length && <>Add All</>}
            </Button>
          </AccordionSummary>
          <AccordionDetails className="accordian-summary">
            {selectedExamType.length === examType.length && (
              <Chip
                className="category-chip"
                label={allExamTypeText}
                onDelete={() => handleDeleteAllCategory("examType")}
                color="primary"
              />
            )}

            {selectedExamType.length !== examType.length &&
              selectedExamType?.sort((a, b) => a.localeCompare(b))?.map((item, index) => (
                <Chip
                  key={index}
                  className="category-chip"
                  label={item}
                  onDelete={() => handleDeleteCategory("examType", item)}
                  color="primary"
                />
              ))}

            {!selectedExamType.length && (
              <Typography className="no-select">
                Select at least one option
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="quiz-selections">
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={`header ${classes.heading}`}>Question Type</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordian-summary">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(evt) => selectQuestionType(evt, "unused", typeCount)}
                    checked={questionOptions.unused}
                    name="unused"
                    color="primary"
                  />
                }
                // label={`Unused [${typeCount.unused}]`}
                label={`Unused`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(evt) => selectQuestionType(evt, "correct", typeCount)}
                    checked={questionOptions.correct}
                    name="correct"
                    color="primary"
                  />
                }
                // label={`Correct [${typeCount.correct}]`}
                label={`Correct`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(evt) => selectQuestionType(evt, "incorrect", typeCount)}
                    checked={questionOptions.incorrect}
                    name="incorrect"
                    color="primary"
                  />
                }
                // label={`Incorrect [${typeCount.incorrect}]`}
                label={`Incorrect`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(evt) => selectQuestionType(evt, "marked", typeCount)}
                    checked={questionOptions.marked}
                    disabled={!questionOptions?.incorrect && !questionOptions?.correct}
                    name="marked"
                    color="primary"
                  />
                }
                // label={`Marked [${typeCount.marked}]`}
                label={`Marked`}
              />
            </FormGroup>

            {!selectedQuesTypeLength && (
              <Typography className="no-select">
                Select at least one option
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="quiz-selections">
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={`header ${classes.heading}`}>Quiz Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="center">
              <Grid item xs={12} md={3}>
                <Grid container alignItems="center">
                  <Grid item>Timer</Grid>
                  <Grid item>
                    <Switch
                      checked={timerMode}
                      onChange={(e) => toggleTimerMode(e)}
                      name="timer"
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    {timerMode && <>On</>}
                    {!timerMode && <>Off</>}
                  </Grid>
                </Grid>
              </Grid>
              {isTutor &&
                <Grid item xs={12} md={3}>
                  <Grid container alignItems="center">
                    <Grid item>Tutor Mode</Grid>
                    <Grid item>
                      <Switch
                        checked={tutorMode}
                        onChange={(e) => toggleTutorMode(e)}
                        name="timer"
                        color="primary"
                      />
                    </Grid>
                    <Grid item>
                      {tutorMode && <>On</>}
                      {!tutorMode && <>Off</>}
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleSubDateClose = () => {
    setSubDateOpen(false);
  };

  const handleQuizErrClose = () => {
    setQuizErr(false);
    setQuizErrMsg('');
  };

  const onTutorModeConfirm = (isConfirm) => {
    setIsTutorConfirmModal(false);

    if (isConfirm) {
      onCreateQuiz(true);
    }
  };

  const gotToSubscription = () => {
    return history.push('subscriptions');
  }

  const onAgreementSubmit = () => {
    return setOpenAgreementModal(false);
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {openAgreementModal &&
        <UserAgreement onAgreementSubmit={onAgreementSubmit}></UserAgreement>
      }

      {/* <Snackbar open={open} onClose={handleClose} autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box className="sub-alert">
          Your daily question limit has been exhausted...
        </Box>
      </Snackbar> */}

      <Snackbar open={subDateOpen} onClose={handleSubDateClose} autoHideDuration={10000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box className="welcome-alert">
          Medlearnity is excited to launch QLearn in Beta. Please share feedback if you notice any issues or have ideas for improvements! Thank you
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSubDateClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Snackbar>

      <Snackbar open={quizErr} onClose={handleQuizErrClose} autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box className="sub-alert">
          {quizErrMsg}
        </Box>
      </Snackbar>


      {/* Tutor confirmation modal  */}
      <Modal
        className='tutor-mode-confirm-modal'
        open={isTutorConfirmModal}
        onClose={() => onTutorModeConfirm(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isTutorConfirmModal}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">Warning!</Typography>
            <Typography variant="body2" >
              You're entering the next window with <b>'Tutor Mode'</b> and <b>'Timer'</b> activated.
              These settings may affect your workflow. <br /> <br /> To proceed,
              click 'Continue'. To change settings, click 'Change Settings'.
            </Typography>

            <Box className="apply-btn">
              <Button variant="contained" onClick={() => onTutorModeConfirm(false)}>
                Change Settings
              </Button>
              <Button variant="contained" className="end-sub-btn" color="primary" onClick={() => onTutorModeConfirm(true)}>
                Continue
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* Question limit modal  */}
      <Modal
        className='tutor-mode-confirm-modal sub-end-modal'
        open={isQuestionLimitModalOpen}
        onClose={() => setIsQuestionLimitModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isQuestionLimitModalOpen}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">Attention</Typography>
            <Typography variant="body2" >
              You've run out of questions for your plan today! <br /> Please upgrade your plan if applicable or come back tomorrow.
            </Typography>

            <Box className="apply-btn">
              <Button variant="contained" onClick={() => setIsQuestionLimitModalOpen(false)}>
                Close
              </Button>
              <Button variant="contained" className="end-sub-btn" color="primary" onClick={gotToSubscription}>
                Subscriptions
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Box className={classes.container}>
        <Menu />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          className="quiz-container"
        >
          <Grid item xs={4} sm={3} className='category-column'>
            <Paper elevation={0} className="app-body-left-container">
              <Categories
                onDisciplineCatSelect={onDisciplineCatSelect}
                onOrgSysCatSelect={onOrgSysCatSelect}
                onExamTypeCatSelect={onExamTypeCatSelect}
                selectedDiscipline={selectedDiscipline}
                selectedOrgSystem={selectedOrgSystem}
                selectedExamType={selectedExamType}
                totalDiscipline={totalDiscipline}
                totalOrgSystem={totalOrgSystem}
                examType={examType}
                isViewDisabled={!!quizProgressId}
              />
            </Paper>
          </Grid>
          <Grid item xs={8} sm={9}>
            <Paper elevation={0} className="app-body-right-container">
              <div className="badge">Beta</div>

              <Box className="user-container">
                <Box className="user-welcome">
                  <Box>
                    Hi {userName}, Welcome to QLearn!
                    {userRole &&
                      <Box component="span" className="user-role">{userRole}</Box>
                    }
                  </Box>
                  {plan?.plan &&
                    <Tooltip arrow classes={{ tooltip: classes.customTooltip }}
                      title={`${plan?.plan?.name}\nDaily Questions Remaining: ${quesCountData?.eligibleQuestionCount}`}>
                      <Box component="p" onClick={gotToSubscription} className={`plan-title-ctx crt-qz ${plan?.expired ? 'expired' : ''}`}>
                        Plan: QLearn {plan?.plan?.category}
                      </Box>
                    </Tooltip>
                  }
                  {/* <Box className="quiz-limit">Quiz limit remaining: <b>{quizLimit}</b></Box> */}
                </Box>
                {!!quizProgressId && (
                  <Box className="user-resume">
                    <Box className="user-resume-text">
                      Looks like you have a quiz in progress. Please, complete
                      your quiz first.
                    </Box>
                    <Button
                      variant="contained"
                      onClick={onResumeQuiz}
                      className="resume-btn"
                    >
                      Resume
                    </Button>
                  </Box>
                )}
              </Box>
              <h2 className="quiz-header"> Quiz me on: </h2>
              <Box

                className={!!quizProgressId ? "is-disabled" : ""}
              >
                {renderQuizOptionSelections()}

                <p><span className={`ques-cnt-txt ${!quesCountData?.questionCount ? 'zero' : ''}`}>
                  {quesCountData?.questionCount || 0}
                </span> <b>Questions Available</b>
                </p>

                <Grid className="lower-container" container>
                  <Grid sm={6} item md={5}>
                    <TextField
                      className={classes.textField}
                      id="quiz-name"
                      value={quizName}
                      onChange={changeQuizName}
                      label="Quiz Name (Required)"
                    />
                  </Grid>
                  <Grid sm={4} item md={5}>
                    <label className="ques-lbl"># of Questions
                      (<span className={`ques-cnt-txt ${!quesCountData?.questionCount ? 'zero' : ''}`}>{max || 0} Max</span>)</label>
                    <TextField
                      className={`${classes.textField} ${(!questionLength || questionLength > quesCountData?.eligibleQuestionCount) ? 'empty-text' : ''}`}
                      value={questionLength}
                      type="number"
                      onChange={changeQuestionsLength}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            / {quesCountData?.eligibleQuestionCount || 0}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {/* (!isTutor && !activePkg) */}

                  <Grid item sm={12} md={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onCreateQuiz(false)}
                      disabled={
                        !selectedDiscipline.length ||
                        !selectedOrgSystem.length ||
                        !selectedExamType.length ||
                        !quizName ||
                        !questionLength ||
                        !selectedQuesTypeLength ||
                        max < 1 ||
                        questionLength > quesCountData?.eligibleQuestionCount
                      }
                    >
                      Create Quiz
                    </Button>
                  </Grid>

                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    fontSize: theme.typography.h4,
    fontWeight: theme.typography.fontWeightMedium,
    flexBasis: "92%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  clearSelection: {
    fontSize: theme.typography.pxToRem(12),
    border: "none",
    padding: 0
  },
  textField: {
    width: "92%",
  },
  customTooltip: {
    whiteSpace: 'pre-wrap'
  }
}));
