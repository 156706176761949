import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExamHeader from "../../components/exam-header/exam-header";
import ExamFooter from "../../components/exam-footer/exam-footer";
import ExamQuestionContainer from "../../components/exam-question-container/exam-question-container";
import ExamQuestionNumberList from "../../components/exam-question-number-list/exam-question-number-list";
import ExamReviewDrawer from "../../components/exam-review-drawer/exam-review-drawer";
import { Box, Backdrop, CircularProgress, IconButton, Snackbar } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import { Launch } from "@material-ui/icons";
import {
  getQuiz,
  submitQuiz,
  resetSubmitQuiz,
  getQuestionMetadata,
  getQuestionHistory,
  reviewQuestion,
  resetQuiz,
  setUserActivity,
  getImage,
  resetQuestionMetadata,
  endTutorFeedbackBlock,
  resetQuesFeedback
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

let examHistoryData = {};
const reviewTimer = {};
const testingTime = {};
let interval;
let guideWindow = null;

export default function ExamPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [showEndBlock, setShowEndBlock] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [currentQuestionNum, setCurrentQuestionNum] = useState(1);
  const [questionsData, setQuestionsData] = useState([]);
  const [questionMetadataArr, setQuestionMetadataArr] = useState([]);
  const [quizHistoryData, setQuizHistoryData] = useState([]);
  const [zoomClass, setZoomClass] = useState("smallFont");
  const [imageData, setImageData] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [quesMetadata, setQuesMetadata] = useState({});
  const [isTutorRole, setIsTutorRole] = useState(false);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState(false);

  const { examId, currentQuestion, review, tutor, guide } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const questionParam = {
    question_list: []
  };

  const examDetails = useSelector((state) => {
    return state.quizDetails?.quizDetails;
  });

  const questionHistory = useSelector((state) => {
    return state.questionHistory?.questionHistory;
  });

  const submitQuizRes = useSelector((state) => {
    return state.submitQuiz?.submitQuiz;
  });

  const questionMetadata = useSelector((state) => {
    return state.questionMetadata?.questionMetadata;
  });

  const reviewQuestionData = useSelector((state) => {
    return state.reviewQuestion;
  });

  const imageDataRes = useSelector((state) => {
    return state.getImage;
  });

  const setQuesTutorFeedbackRes = useSelector((state) => {
    return state.setQuesTutorFeedback;
  });

  const handleClose = () => {
    setOpen(false);
    setErrMsg(false);
  };

  const startTimer = () => {
    const questionData = questionsData[currentQuestionNum - 1];

    if (review) {
      const questionHistoryData = questionHistory.filter(
        (item) => item.questionId === questionData?.questionId
      );

      interval = setInterval(() => {
        reviewTimer[questionData?.questionId] =
          reviewTimer[questionData?.questionId] + 1 ||
          questionHistoryData[0]?.reviewTimeSpent ||
          1;
      }, 1000);
    } else {
      interval = setInterval(() => {
        testingTime[questionData?.questionId] = testingTime[questionData?.questionId] + 1 || 1;
      }, 1000);
    }
  };

  const updateReviewApi = () => {
    const questionData = questionsData[currentQuestionNum - 1];
    const questionHistoryData = questionHistory.filter(
      (item) => item.questionId === questionData?.questionId
    );

    const param = {
      questionId: questionHistoryData[0]?.id,
      reviewTime: {
        reviewTimeSpent: reviewTimer[questionData?.questionId],
      },
    };

    dispatch(reviewQuestion(param));
  };

  const endFeedBackBlock = () => {
    const blockId = window.localStorage.getItem('feedbackBlockId');

    if (blockId) {
      dispatch(endTutorFeedbackBlock(blockId));
      window.localStorage.removeItem('feedbackBlockId');
    }
  }

  const preventNav = (event) => {
    updateReviewApi();

    const e = event || window.event;
    e.preventDefault();

    if (e) {
      e.returnValue = "";
    }

    return "";
  };

  useEffect(() => {
    const role = window.localStorage.getItem('role');
    const isTutorRole = role === 'ROLE_TUTOR';
    setIsTutorRole(isTutorRole);

    if (!isTutorRole) {
      window.addEventListener("beforeunload", preventNav);
      if (history.action === "POP") {
        history.push("/create-quiz");
      }
    }

    return () => {
      dispatch(resetQuesFeedback());
      handleClose();

      if (guideWindow) {
        guideWindow.close();
      }

      if (!isTutorRole) {
        window.removeEventListener("beforeunload", preventNav);

        setTimeout(() => {
          dispatch(resetQuiz());
          setQuestionsData([]);
        }, 300);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getQuiz(examId));

    if (review || tutor) {
      dispatch(getQuestionHistory(examId));
    }
  }, [dispatch, examId, review, tutor]);

  useEffect(() => {
    if (examDetails && examDetails.questions) {
      setQuestionsData(examDetails.questions);
      return;
    }
    setQuestionsData([]);
  }, [examDetails]);

  useEffect(() => {
    if (questionsData?.length) {
      questionsData.forEach((element) => {
        questionParam.question_list.push(element.questionId);
      });

      if (questionParam.question_list.length && !questionMetadata) {
        dispatch(getQuestionMetadata(questionParam));
        if (questionHistory?.length) {
          setQuizHistoryData(questionHistory);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, questionHistory, questionsData]);

  useEffect(() => {
    const number = parseInt(currentQuestion);
    setCurrentQuestionNum(number);

    if (questionsData && questionsData.length) {
      const questionData = questionsData[currentQuestionNum - 1];

      if (questionData?.imageList?.length && (!imageDataRes?.image || !imageDataRes?.image[questionData.imageList[0]])) {
        dispatch(getImage(questionData.imageList[0]));
      }

      if (!tutor && !review) {
        startTimer();

        return () => {
          clearInterval(interval);
        };
      } else if (review && questionHistory) {
        startTimer();

        return () => {
          examHistoryData = {};
          clearInterval(interval);
          updateReviewApi();
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, questionsData, questionHistory]);

  useEffect(() => {
    if (questionMetadata && questionMetadata.length) {
      setQuestionMetadataArr(questionMetadata);
    }
  }, [questionMetadata]);

  useEffect(() => {
    if (reviewQuestionData && reviewQuestionData.isLoading !== undefined) {
      setIsLoading(reviewQuestionData.isLoading);
    }
  }, [reviewQuestionData]);

  useEffect(() => {
    if (submitQuizRes && submitQuizRes.quizStatus === "COMPLETE") {
      const userinfos = JSON.parse(localStorage.getItem('userdetails'));
      const userrole = window.localStorage.getItem("role");
      history.push(`/result/${examId}`);
      examHistoryData = {};
      dispatch(resetSubmitQuiz());
      dispatch(resetQuiz());
      setQuestionsData([]);

      if (review) {
        window.analytics.track('Review quiz completed', {
          examId
        });
        mixpanel.track('Review quiz completed', {
          examId
        });
      } else if (tutor) {
        window.analytics.track('Tutoring quiz completed', {
          examId
        });
        mixpanel.track('Tutoring quiz completed', {
          examId
        });
      } else {
        window.analytics.track('Quiz completed', {
          examId
        });
        mixpanel.track('Quiz completed', {
          $name: userinfos.name,
          "Value": 10.00, // Assign a monetary value to the event
          email: userinfos.email,
          role: userrole
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, examId, history, submitQuizRes]);

  useEffect(() => {
    const questionData = questionsData[currentQuestionNum - 1];
    if (imageDataRes && imageDataRes?.loading) {
      setIsImageLoading(true);
      return;
    }

    if (imageDataRes?.image && questionData?.imageList?.length && imageDataRes?.image[questionData.imageList[0]]) {
      setImageData(imageDataRes?.image[questionData.imageList[0]]);
    } else {
      setImageData('');
    }
    setIsImageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageDataRes, currentQuestion, questionsData]);

  useEffect(() => {
    if (isTutorRole && questionsData) {
      const questionData = questionsData[currentQuestionNum - 1];
      if (questionMetadata && questionMetadata.length) {
        const currentQues = questionMetadata.filter(
          (item) => item.id === questionData?.questionId
        );
        setQuesMetadata(currentQues[0]);
        const channel = new BroadcastChannel('app-data');
        channel.postMessage(currentQues[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionMetadata, currentQuestionNum]);

  useEffect(() => {
    if (setQuesTutorFeedbackRes?.quesTutorFeedback && setQuesTutorFeedbackRes?.loading === false) {
      setOpen(true);
    }

    if (setQuesTutorFeedbackRes?.errorMsg && setQuesTutorFeedbackRes?.loading === false) {
      setErrMsg(setQuesTutorFeedbackRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQuesTutorFeedbackRes]);

  const handleQuestionSelection = (questionIndex) => {
    setCurrentQuestionNum(questionIndex);

    if (tutor) {
      history.push(`/exam/${examId}/${questionIndex}/tutor/1`);
    } else if (!review) {
      history.push(`/exam/${examId}/${questionIndex}`);
    } else {
      addUserActivityOnReview();
      history.push(`/exam/${examId}/${questionIndex}/review`);
    }
  };

  const handleTextZoom = (className) => {
    setZoomClass(className);
  };

  const handleProceedToNextQuestion = () => {
    const num = currentQuestionNum + 1;
    setCurrentQuestionNum(num);

    if (tutor) {
      history.push(`/exam/${examId}/${num}/tutor/1`);
    } else if (!review) {
      history.push(`/exam/${examId}/${num}`);
    } else {
      addUserActivityOnReview();
      history.push(`/exam/${examId}/${num}/review`);
    }
  };

  const onExamHistoryChange = (data, type, extraData) => {
    examHistoryData = { ...data };

    if (type === "marked") {
      setQuestionsData([...extraData]);
    }
  };

  const getMarkedVal = (quesId) => {
    const ques = questionMetadata?.filter(item => item.id === quesId);

    return ques?.length ? ques[0].marked : false;
  };

  const onEndBlock = (timeLeft, type) => {
    setShowEndBlock(false);
    setImageData('');
    dispatch(resetQuestionMetadata());

    if (!review) {
      const timePerQues = 90;
      const timeSpent = timePerQues * questionsData.length - timeLeft;

      const questionHistories = questionsData.map((item) => {
        return {
          questionId: item.questionId,
          answerGiven: examHistoryData[item.questionId]?.answerGiven,
          marked: examHistoryData[item.questionId]?.marked !== undefined ? examHistoryData[item.questionId]?.marked : getMarkedVal(item.questionId),
          timeSpent: testingTime[item.questionId],
          answersAttempted: [],
          questionNo: item.questionNo
        };
      });

      const param = {
        questionHistories,
        quizHistory: {
          quizId: examId,
          userId: examDetails.userId,
          questionAttemptCount: questionsData.length,
          quizSubmitDate: moment().format('YYYY-MM-DDTHH:mm:ss.sss') + 'Z',
          quizDuration: timeSpent,
        },
      };

      dispatch(submitQuiz(param));
    } else {
      clearInterval(interval);

      if (type === "submitted" || type === "reviewEnd") {
        endFeedBackBlock();
        updateReviewApi();
        if (type === "reviewEnd") {
          history.push('/create-quiz');
        } else {
          history.push(`/result/${examId}`);
        }
        setQuestionsData([]);
      } else if (type === "remain-in-block") {
        startTimer();
      }
    }
  };

  const addUserActivityOnReview = () => {
    const savedUserId = window.localStorage.getItem("userId");
    dispatch(setUserActivity({
      userId: savedUserId,
      videoAccessCount: 1
    }));
  };

  const onShowEndBlock = (show) => {
    setShowEndBlock(show);
  };

  const onFeedbackOpen = (show) => {
    setFeedbackOpen(show);
  };

  const goToHomePage = () => {
    if (review) {
      dispatch(resetQuestionMetadata());
      clearInterval(interval);
      endFeedBackBlock();
      updateReviewApi();
      history.push(`/create-quiz`);
    }
  };

  const onGuidePopOut = () => {
    if (!guideWindow) {
      let path = window.location.pathname;

      if (review) {
        path = path.replace('review', 'tutor/1');
      }

      guideWindow = window.open(path + '/guide/1', '_blank', 'width=800,height=700,left=700,popup');

      guideWindow.addEventListener('load', () => {
        guideWindow.addEventListener('beforeunload', (e) => {
          e.preventDefault();
          guideWindow = null;
          e.returnValue = true;
        });
      });
    } else {
      alert('Guide already open');
    }
  }

  if (!questionsData.length) {
    return null;
  }

  return (
    <div className={`${classes[zoomClass]} ${!review ? 'quiz-page' : 'review-page'}`}>
      <Snackbar open={open} onClose={handleClose} autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box className="success-alert">
          Thank you for your feedback...
        </Box>
      </Snackbar>
      <Snackbar open={errMsg} onClose={handleClose} autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box className="sub-alert">
          {errMsg}
        </Box>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop className={classes.backdrop} open={isImageLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {!guide &&
        <Box component="div" className={classes.examContainer}>
          <ExamQuestionNumberList
            questionData={questionsData}
            onSelectQuestion={handleQuestionSelection}
            examHistoryData={examHistoryData}
            quizHistoryData={quizHistoryData}
            questionMetaData={questionMetadataArr}
            isReview={!!review}
          />
          <Box component="div" className={`${classes.examContentContainer} exam-container-wrapper`}>
            <ExamHeader
              currentQuestion={currentQuestionNum}
              questionData={questionsData}
              questionMetaData={questionMetadataArr}
              onSelectQuestion={handleQuestionSelection}
              onTextZoom={handleTextZoom}
              examHistoryData={examHistoryData}
              onExamHistoryChange={onExamHistoryChange}
              quizHistoryData={quizHistoryData}
              isReview={!!review}
              isTutor={!!tutor}
              feedbackOpen={feedbackOpen}
              onFeedbackClose={onFeedbackOpen}
              goToHomePage={goToHomePage}
            />
            {isTutorRole && tutor &&
              <IconButton
                onClick={() => onGuidePopOut()}
                title="Pop Out"
                className="pop-out-icon-outer"
              >
                <Launch />
              </IconButton>
            }
            <ExamQuestionContainer
              currentQuestionNum={currentQuestionNum}
              dataLength={questionsData.length}
              questionData={questionsData[currentQuestionNum - 1]}
              onProceedToNextQuestion={handleProceedToNextQuestion}
              isReview={!!review}
              isTutor={!!tutor}
              examHistoryData={examHistoryData}
              onExamHistoryChange={onExamHistoryChange}
              questionMetadata={questionMetadataArr}
              quizHistoryData={quizHistoryData}
              imageData={imageData}
              onShowEndBlock={onShowEndBlock}
              onFeedbackOpen={onFeedbackOpen}
            />
            <ExamFooter
              isReview={!!review}
              isTutor={!!tutor}
              quizDetails={examDetails}
              questionsDataLength={questionsData.length}
              onEndBlock={onEndBlock}
              showEndBlock={showEndBlock}
              currentQuestionNum={currentQuestionNum}
            />
          </Box>
        </Box>
      }
      {guide &&
        <>
          <ExamReviewDrawer
            questionData={quesMetadata}
            openDrawer={true}
            isTutor={tutor}
            isGuide={guide}
            isReview={review}
            onFeedbackOpen={(evt, data) => onFeedbackOpen(true, data)}
          ></ExamReviewDrawer>
        </>
      }
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  examContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  examContentContainer: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
  smallFont: {
    zoom: "100%",
  },
  mediumFont: {
    zoom: "110%",
  },
  largeFont: {
    zoom: "120%",
  },
}));
