import { useState, useEffect } from "react";
import { Box, Backdrop, CircularProgress } from "@material-ui/core";
import QuizHistoryTable from "../../components/quiz-history-table/quiz-history-table";
import Menu from "../../pages/menu/menu";
import { getStudentQuizHistory, getActivePlans } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

export default function QuizHistory() {
  const [quizHistoryData, setQuizHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState({});
  const dispatch = useDispatch();

  const studentQuizHistory = useSelector((state) => {
    return state.studentQuizHistory;
  });

  const activePlans = useSelector((state) => {
    return state.getActivePlans;
  });

  useEffect(() => {
    const userId = window.localStorage.getItem("userId");
    dispatch(getStudentQuizHistory(userId));
  }, [dispatch]);

  useEffect(() => {
    if (studentQuizHistory?.studentQuizHistory) {
      setQuizHistoryData(studentQuizHistory.studentQuizHistory);
    }

    if (studentQuizHistory?.loading !== undefined) {
      setIsLoading(studentQuizHistory?.loading);
    }
  }, [studentQuizHistory]);

  useEffect(() => {
    const savedUserId = window.localStorage.getItem("userId");

    if (activePlans?.loading === undefined) {
      dispatch(getActivePlans(savedUserId));
    } else if (activePlans?.plansList) {
      const activePkg = activePlans?.plansList.filter(item => item.iselected)[0];
      setPlan(activePkg || {});
    }
  }, [activePlans, dispatch]);

  return (
    <Box component="div" className='body-cont-over'>
      <Menu />
      <Backdrop open={isLoading} className="quiz-loader">
        <CircularProgress color="inherit" />
      </Backdrop>

      {plan?.plan &&
        <Box component="p" className={`plan-title-ctx ${plan?.expired ? 'expired' : ''}`}>
          Plan: QLearn {plan?.plan?.category}
        </Box>
      }

      <QuizHistoryTable questions={quizHistoryData} isLoading={isLoading} />
    </Box>
  );
}
