import { useEffect, useState } from "react";
import SubscriptionsListView from "../../components/subscriptions/subscriptions-list";
import CurrentPlanView from "../../components/subscriptions/current-plan";
import Menu from "../../pages/menu/menu";
import {
  Box, CircularProgress, Typography, Button,
  Snackbar
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPlans, userDetails, createPaymentSession,
  updatePaymentStatus, getUserPackage, getUserActivity,
  getUserPayments, cancelSubscription, resetCancelSubscription,
  resetPaymentSession,
  getActivePlans
} from "../../actions";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { DoneOutlineOutlined, Close } from "@material-ui/icons";
import mixpanel from 'mixpanel-browser';
import '../../components/subscriptions/subscriptions-list';

export default function SubscriptionsView(props) {
  const { isPlan } = props;
  const [paymentStatus, setPaymentStatus] = useState('');
  const [activePackage, setActivePackage] = useState({});
  const [showPlan, setShowPlan] = useState(null);
  const [userActivityDetails, setUserActivityDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [onPaymentLoadSuccess, setOnPaymentLoadSuccess] = useState(false);
  const [emailNotify, setEmailNotify] = useState(false);
  const [showPaymentIframe, setShowPaymentIframe] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  const dispatch = useDispatch();
  let { bookingId } = useParams();
  let location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const userData = useSelector((state) => {
    return state.studentDetails.userDetails;
  });

  const packageListRes = useSelector((state) => {
    return state.getAllPlans;
  });

  const paymentSession = useSelector((state) => {
    return state.paymentSession?.paymentSession;
  });

  const paymentStatusRes = useSelector((state) => {
    return state.paymentStatus?.paymentUpdateStatus;
  });

  const userPackage = useSelector((state) => {
    return state.userPackage?.userPackage;
  });

  const userActivity = useSelector((state) => {
    return state.userActivity?.userActivity;
  });

  const paymentHistory = useSelector((state) => {
    return state.userPayments?.paymentHistory;
  });

  const cancelSubscriptionReq = useSelector((state) => {
    return state.cancelSubscription?.cancelSubscription;
  });

  const activePlans = useSelector((state) => {
    return state.getActivePlans;
  });

  useEffect(() => {
    if (bookingId) {
      const isSuccess = location.pathname.search('success') > -1;
      const status = isSuccess ? 'success' : 'failure';
      setPaymentStatus(status);
      dispatch(updatePaymentStatus({
        isSuccess,
        bookingId
      }));

      if (isSuccess) {
        window.analytics.track('Subscription bought', {
          packageName: activePackage?.packages?.name
        });
        mixpanel.track('Subscription bought', {
          packageName: activePackage?.packages?.name
        });
      }
    }

    if (!packageListRes.plans) {
      dispatch(getAllPlans());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const savedUserId = window.localStorage.getItem("userId");

    if (savedUserId && savedUserId !== "undefined") {
      if (!userData) {
        dispatch(userDetails(savedUserId));
      }
      dispatch(getUserPackage(savedUserId));
      dispatch(getUserActivity(savedUserId));
      dispatch(getUserPayments(savedUserId));
      dispatch(getActivePlans(savedUserId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (paymentSession && paymentSession.checkoutURL) {
      window.open(paymentSession.checkoutURL, '_self');
      // setIframeUrl(paymentSession.checkoutURL);
      setOnPaymentLoadSuccess(true);
      // setShowPaymentIframe(true);
      dispatch(resetPaymentSession());

      window.analytics.track('Subscription payment url opened', {
        packageName: activePackage?.packages?.name
      });
      mixpanel.track('Subscription payment url opened', {
        packageName: activePackage?.packages?.name
      });
    } else if (paymentSession && paymentSession.booking?.id) {
      setTimeout(() => { dispatch(getUserPayments(savedUserId)) }, 2000);
      // setShowPaymentIframe(false);
      history.push('/subscriptions');
      setOpen(true);
      setEmailNotify(true);
      setOnPaymentLoadSuccess(true);

      const savedUserId = window.localStorage.getItem("userId");
      dispatch(getUserPackage(savedUserId));
      dispatch(resetPaymentSession());

      window.analytics.track('Subscription requested', {
        packageName: activePackage?.packages?.name
      });
      mixpanel.track('Subscription requested', {
        packageName: activePackage?.packages?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, paymentSession]);

  useEffect(() => {
    if (userPackage) {
      const activePkg = userPackage.length === 1 ? userPackage[0] :
        userPackage.filter(item => item.status === 'ACTIVE')[0];

      setActivePackage(activePkg || {});
    }
  }, [userPackage]);

  useEffect(() => {
    if (userActivity) {
      setUserActivityDetails(userActivity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActivity]);

  useEffect(() => {
    if (cancelSubscriptionReq) {
      setOpen(true);
      dispatch(resetCancelSubscription());
      const savedUserId = window.localStorage.getItem("userId");
      dispatch(getUserPackage(savedUserId));
      dispatch(getUserPayments(savedUserId));
      dispatch(getActivePlans(savedUserId));

      window.analytics.track('Subscription cancelled', {
        packageName: activePackage?.packages?.name
      });
      mixpanel.track('Subscription cancelled', {
        packageName: activePackage?.packages?.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSubscriptionReq]);

  const onConfirmation = (data) => {
    dispatch(createPaymentSession(data));
  }

  const subcriptionEndConfirm = (subscriptionId) => {
    const payment = paymentHistory.filter(item => item.subscriptionId === subscriptionId);
    if (payment && payment.length) {
      dispatch(cancelSubscription(payment[0].id));
    }
  }

  const closeStatus = () => {
    history.push("/subscriptions");
  }

  const handleClose = () => {
    setOpen(false);
    setEmailNotify(false);
  };

  const showPlanDetails = (planObj) => {
    setShowPlan(planObj.plan);
  };

  const hidePlanDetailsModal = () => {
    setShowPlan(null);
  };

  const useStyles = makeStyles(() => ({
    examContainer: {
      display: "flex"
    },
    loaderCont: {
      position: 'absolute',
      left: '50%',
      top: '42%'
    },
    statusCont: {
      display: "flex",
      justifyContent: 'center',
      marginTop: 200,
      flex: 1
    },
    statusContWrapper: {
      boxShadow: '1px 5px 5px #dcdcdc',
      height: 310,
      padding: 20,
      width: 500,
      textAlign: 'center',
      border: '1px solid #dcdcdc',
      [theme.breakpoints.down(600)]: {
        width: 'auto'
      }
    },
    icon: {
      background: '#5cb85c',
      padding: 10,
      borderRadius: 40,
      color: '#fff',
      marginBottom: 5
    },
    iconRed: {
      background: 'red',
      padding: 10,
      borderRadius: 40,
      color: '#fff',
      marginBottom: 5
    },
    header: {
      textAlign: 'center',
      marginBottom: 20
    },
    item: {
      lineHeight: '35px',
      textAlign: 'left'
    },
    itemText: {
      float: 'right',
      fontWeight: 'bold'
    },
    btn: {
      marginTop: 20
    }
  }));

  const classes = useStyles();

  return (
    <Box component="div" className='body-cont'>
      <Snackbar open={open} onClose={handleClose} autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>

        <Box className="success-alert">
          {!emailNotify &&
            <>Your subscription has been cancelled successfully...</>
          }
          {emailNotify &&
            <>An email has been sent to your registered mail id with all the details.
              Your payment will be auto-debited.</>
          }
        </Box>
      </Snackbar>
      <Menu />
      {!bookingId &&
      !showPaymentIframe &&
        <>
          {packageListRes?.loading && (
            <Box className={classes.loaderCont}><CircularProgress size={24} className="loader" /></Box>
          )}

          {!isPlan && !packageListRes?.loading &&
            <Box className="wd-100">
              <Typography variant="h4" className="plans-title">
                Plans and Billing
              </Typography>
              <Box className="plans-wrapper">
                <SubscriptionsListView
                  activePackage={activePackage}
                  userActivity={userActivityDetails}
                  packageList={packageListRes.plans}
                  userData={userData}
                  onPaymentLoadSuccess={onPaymentLoadSuccess}
                  onConfirmation={onConfirmation}
                  showPlanDetailsModal={showPlan}
                  hidePlanDetailsModal={hidePlanDetailsModal}
                  />
                <CurrentPlanView
                  activePackage={activePackage}
                  paymentHistory={paymentHistory}
                  activePlans={activePlans}
                  userActivity={userActivityDetails}
                  subcriptionEndConfirm={subcriptionEndConfirm}
                  showPlanDetails={showPlanDetails}
                />
              </Box>
            </Box>
          }
        </>
      }

      {
        showPaymentIframe &&
        <iframe src={iframeUrl} title="Payment iframe" className="payment-iframe"></iframe>
      }

      {bookingId &&
        <>
          {paymentStatus === 'success' && paymentStatusRes &&
            <Box className={classes.statusCont}>
              <Box className={classes.statusContWrapper}>
                <Box className={classes.header}>
                  <DoneOutlineOutlined className={classes.icon} />
                  <Typography component="div" variant="h5"><b>Payment Successful</b></Typography>
                </Box>
                <Box>
                  <Typography className={classes.item} >Package name: <span className={classes.itemText}>{paymentStatusRes.plan.name}</span></Typography>
                  <Typography className={classes.item}>Amount paid: <span className={classes.itemText}>${paymentStatusRes.price}</span></Typography>
                  <Typography className={classes.item}>Transaction Id: <span className={classes.itemText}>{paymentStatusRes.id}</span></Typography>
                  <Typography className={classes.item}>Email: <span className={classes.itemText}>{paymentStatusRes.user.email}</span></Typography>
                </Box>
                <Button variant="contained" color="primary" onClick={closeStatus} className={classes.btn}>
                  Subscriptions
                </Button>
              </Box>
            </Box>
          }
          {paymentStatus === 'failure' && paymentStatusRes &&
            <Box className={classes.statusCont}>
              <Box className={classes.statusContWrapper}>
                <Box className={classes.header}>
                  <Close className={classes.iconRed} />
                  <Typography component="div" variant="h5"><b>Payment Failed</b></Typography>
                </Box>
                <Box>
                  <Typography className={classes.item} >Package name: <span className={classes.itemText}>{paymentStatusRes?.plan?.name}</span></Typography>
                  {paymentStatusRes?.paymentIntentId &&
                    <Typography className={classes.item}>Transaction Id: <span className={classes.itemText}>{paymentStatusRes?.paymentIntentId}</span></Typography>
                  }
                  <Typography className={classes.item}>Email: <span className={classes.itemText}>{paymentStatusRes?.user?.email}</span></Typography>
                </Box>
                <Button variant="contained" color="primary" onClick={closeStatus} className={classes.btn}>
                  Try Again
                </Button>
              </Box>
            </Box>
          }
        </>
      }
    </Box>
  );
}