import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Flag, HighlightOffTwoTone, CheckCircleRounded } from "@material-ui/icons";
import "./exam-question-number-list.scss";

const ExamQuestionNumberList = (props) => {
  const { questionData, onSelectQuestion, examHistoryData, quizHistoryData, questionMetaData, isReview } = props;
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  let { currentQuestion } = useParams();

  useEffect(() => {
    const number = parseInt(currentQuestion);
    setSelectedQuestion(number);
  }, [currentQuestion]);

  const selectQuestion = (number) => {
    setSelectedQuestion(number);
    onSelectQuestion(number);
  };

  const getQuestionMarked = (id) => {
    return questionMetaData?.filter(item => item.id === id)[0];
  };

  const getQuestionHistory = (id) => {
    return quizHistoryData?.filter(item => item.questionId === id)[0];
  };

  return (
    <Box component="div" className="list">
      {questionData &&
        questionData.map((item, index) => {
          return (
            <Box
              component="div"
              key={index}
              onClick={() => selectQuestion(index + 1)}
              className={`${index + 1 === selectedQuestion ? "active" : ''}
              ${(examHistoryData[item.questionId]?.answerGiven !== undefined ||
                getQuestionHistory(item.questionId)?.answerGiven) ?
                  'answered' : ''}`}
            >
               {(isReview && getQuestionHistory(item.questionId)?.correct) && <CheckCircleRounded className="correct-icon ans-icon" />}
               {(isReview && !getQuestionHistory(item.questionId)?.correct) && <HighlightOffTwoTone className="incorrect-icon ans-icon" />}
              {index + 1}
              {(getQuestionMarked(item.questionId)?.marked || item?.marked) && <Flag className="marked-icon" />}
            </Box>
          );
        })}
    </Box>
  );
};

export default ExamQuestionNumberList;
