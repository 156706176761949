import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import OverallPerformanceHeader from "../../components/overall-performance-header/overall-performance-header";
import OverallPerformanceTabs from "../../components/overall-performance-tabs/overall-performance-tabs";
import Menu from "../../pages/menu/menu";
import {
  getOverallQuizHistory,
  getOverallStudentQuizHistory,
  getQuizHistory,
  getActivePlans
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from 'mixpanel-browser';

import moment from "moment";

export default function OverallPerformance() {
  const [performanceData, setPerformanceData] = useState();
  const [quizHistoryList, setQuizHistoryList] = useState();
  const [quizDetails, setQuizDetails] = useState();
  const [plan, setPlan] = useState({});
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const overallQuizHistory = useSelector((state) => {
    return state.overallQuizHistory?.overallQuizHistory;
  });

  const overallStudentQuiz = useSelector((state) => {
    return state.overallStudentQuiz?.overallStudentQuiz;
  });

  const quizHistory = useSelector((state) => {
    return state.quizHistory?.quizHistory;
  });

  const activePlans = useSelector((state) => {
    return state.getActivePlans;
  });

  useEffect(() => {
    const userId = window.localStorage.getItem("userId");
    dispatch(getOverallQuizHistory(userId));
    dispatch(getOverallStudentQuizHistory({ userId, isCompleted: true }));
  }, [dispatch]);

  useEffect(() => {
    if (overallQuizHistory) {
      setPerformanceData(overallQuizHistory);
    }
  }, [overallQuizHistory]);

  useEffect(() => {
    if (overallStudentQuiz?.length) {
      setQuizHistoryList(overallStudentQuiz);
      dispatch(getQuizHistory(overallStudentQuiz[0]?.id));
    }
  }, [dispatch, overallStudentQuiz]);

  useEffect(() => {
    if (quizHistory) {
      setQuizDetails(quizHistory);
    }
  }, [quizHistory]);

  useEffect(() => {
    const savedUserId = window.localStorage.getItem("userId");

    if (activePlans?.loading === undefined) {
      dispatch(getActivePlans(savedUserId));
    } else if (activePlans?.plansList) {
      const activePkg = activePlans?.plansList.filter(item => item.iselected)[0];
      setPlan(activePkg || {});
    }
  }, [activePlans, dispatch]);

  const onNavigateToReview = (quizId) => {
    const userinfos = JSON.parse(localStorage.getItem('userdetails'));
    const userrole = window.localStorage.getItem("role");
    mixpanel.track('OP-review', {
      $name: userinfos.name,
      email: userinfos.email,
      role: userrole
    });
    history.push(`/result/${quizId}`);
  };

  return (
    <Box component="div" className="body-cont-over">
      <Menu />
      <Box component="div" className={`${classes.examContainerWrapper} exam-container-wrapper`}>
        {plan?.plan &&
          <Box component="p" className={`plan-title-ctx ovrl-perf ${plan?.expired ? 'expired' : ''}`}>
           Plan: QLearn {plan?.plan?.category}
          </Box>
        }
        <OverallPerformanceHeader
          navigateToReview={onNavigateToReview}
          performanceData={performanceData}
          quizHistoryList={quizHistoryList}
          quizDetails={quizDetails}
        />
        <OverallPerformanceTabs
          performanceData={performanceData?.categoriesPerformance}
        />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  examContainer: {
    display: "flex",
  },
  examContainerWrapper: {
    flex: 1,
    margin: 25,
  },
}));
