import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  MenuItem,
  Button,
  Modal,
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  CircularProgress,
  Checkbox,
  ListItemText,
  Input,
} from "@material-ui/core";
import {
  getAllPlans,
  createPlan,
  resetCreatePlan,
  updatePlan,
  resetUpdatePlan,
  deletePlan,
  resetDeletePlan,
  sendInvites,
  resetSendInvites
} from "../../actions";
import { Edit, Delete } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from '../../constants/app-constant'
import Menu from "../menu/menu";
import "./admin.scss";
const maxLimit = {
  questionsPerDay: 400
};
const minLimit = {
  questionsPerDay: 1
};

const DefaultTypes = CONSTANTS.examTypeList;
const DefaultTypesName = CONSTANTS.examTypeList.map(item => item.name);

export default function AdminPlanView() {
  const [planList, setPlanList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [deletePlanModal, setDeletePlanModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [planForm, setPlanForm] = useState({
    name: '',
    questionsPerDay: '',
    category: '',
    price: '',
    durationInDay: '',
    description: '',
    type: 'GENERAL'
  });

  const [inviteForm, setInviteForm] = useState({
    planId: '',
    email: ''
  });

  const [selectedExamTypes, setSelectedExamTypes] = useState(DefaultTypes);
  const [selectedExamTypesName, setSelectedExamTypesName] = useState(DefaultTypesName);

  const dispatch = useDispatch();

  const getAllPlansList = useSelector((state) => {
    return state.getAllPlans;
  });

  const getDeletePlanRes = useSelector((state) => {
    return state.deletePlan;
  });

  const createPlanRes = useSelector((state) => {
    return state.createPlan;
  });

  const updatePlanRes = useSelector((state) => {
    return state.updatePlan;
  });

  const sendInvitesRes = useSelector((state) => {
    return state.sendInvites;
  });

  useEffect(() => {
    dispatch(getAllPlans());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getAllPlansList?.loading === false) {
      setPlanList(getAllPlansList?.plans);
    }
  }, [getAllPlansList]);

  useEffect(() => {
    if (sendInvitesRes?.loading === false) {
      setSnackbarOpen(true);
      onInvitesModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendInvitesRes]);

  useEffect(() => {
    if (getDeletePlanRes?.loading !== undefined) {
      setIsLoading(getDeletePlanRes.loading);
    }

    if (getDeletePlanRes?.loading === true) {
      setDeletePlanModal(false);
    } else if (getDeletePlanRes?.loading === false) {
      if (!getDeletePlanRes.plan?.errMsg) {
        dispatch(getAllPlans());
      }

      setSnackbarOpen(true);
      setTimeout(() => dispatch(resetDeletePlan()), 2100);
    }
  }, [dispatch, getDeletePlanRes]);

  useEffect(() => {
    if (createPlanRes?.loading !== undefined) {
      setIsLoading(createPlanRes.loading);
    }

    if (createPlanRes?.loading === true) {
      onModalClose();
    } else if (createPlanRes?.loading === false) {
      if (!createPlanRes?.plan?.errStatus) {
        dispatch(getAllPlans());
      }
      setSnackbarOpen(true);
      setTimeout(() => dispatch(resetCreatePlan()), 2100);
    }
  }, [dispatch, createPlanRes]);

  useEffect(() => {
    if (updatePlanRes?.loading !== undefined) {
      setIsLoading(updatePlanRes.loading);
    }

    if (updatePlanRes?.loading === true) {
      onModalClose();
    } else if (updatePlanRes?.loading === false) {
      if (!updatePlanRes?.plan?.errStatus) {
        dispatch(getAllPlans());
      }

      setSnackbarOpen(true);
      setTimeout(() => dispatch(resetUpdatePlan()), 2100);
    }
  }, [dispatch, updatePlanRes]);

  const editPlan = (row) => {
    setSelectedPlan(row);
    setPlanForm(row);
    setIsEdit(true);
    setModal(true);

    const examTypes = row.examTypes.map(item => {
     const selectedObj = DefaultTypes.filter(defaultItem => defaultItem.name === item);
      return selectedObj
    })
    setSelectedExamTypes(...examTypes);
    setSelectedExamTypesName(row?.examTypes);
  };

  const onDeletePlan = (row) => {
    setSelectedPlan(row);
    setDeletePlanModal(true);
  };

  const onDeletePlanConfirm = () => {
    setDeletePlanModal(false);
    dispatch(deletePlan(selectedPlan?.id));
    setSelectedPlan({});
  };

  const onCreatePlan = () => {
    planForm.videoExplanationLimit = 1;  // TOBE removed

    const params = { ...planForm, examTypes: selectedExamTypes.map(item => item.value) };

    if (isEdit) {
      dispatch(updatePlan(params));
    } else {
      dispatch(createPlan(params));
    }
  };

  const onSendInvites = () => {
    dispatch(sendInvites(inviteForm));
  };

  const openCreateModal = () => {
    setIsEdit(false);
    setModal(true);
  };

  const onModalClose = () => {
    setModal(false);

    setTimeout(() => {
      setSelectedPlan({});
      setPlanForm({
        name: '',
        questionsPerDay: '',
        category: '',
        price: '',
        durationInDay: '',
        type: 'GENERAL'
      });
    }, 600);

    setSelectedExamTypes(DefaultTypes);
    setSelectedExamTypesName(DefaultTypesName);
  };

  const onInvitesModalClose = () => {
    setInviteModal(false);

    setTimeout(() => {
      setInviteForm({
        planId: '',
        email: ''
      });
      dispatch(resetSendInvites());
    }, 600);
  };


  const isSubmitDisabled = () => {
    let isDisabled = false;

    isDisabled = (planForm?.name?.trim() === '' ||
      planForm?.questionsPerDay === undefined ||
      !planForm?.questionsPerDay ||
      planForm?.category === undefined ||
      planForm?.price === undefined ||
      planForm?.durationInDay === undefined ||
      !planForm?.durationInDay ||
      planForm?.type === undefined ||
      !planForm?.type ||
      !selectedExamTypesName?.length
    )

    return isDisabled;
  };

  const isInvitesSubmitDisabled = () => {
    let isDisabled = false;

    isDisabled =
      inviteForm?.planId === undefined ||
      !inviteForm?.planId ||
      inviteForm?.email === undefined ||
      !inviteForm?.email ||
      !(/\S+@\S+\.\S+/i).test(inviteForm?.email);

    return isDisabled;
  };

  const handleChange = (evt, type, isNum = false) => {
    let val = evt.target.value;
    const formVal = { ...planForm };

    if (type === 'questionsPerDay') {
      val = val > maxLimit[type] ? maxLimit[type] : (val < minLimit[type] ? minLimit[type] : val);
    }

    if (isNum) {
      val = Number(val);
    }

    formVal[type] = val;
    setPlanForm(formVal);
  };

  const handleInvitesChange = (evt, type) => {
    let val = evt.target.value;
    const formVal = { ...inviteForm };

    formVal[type] = val;
    setInviteForm(formVal);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  }

  const examTypeMultipleSelect = (event) => {
    const val = event.target.value;
    const nameList = val.map(item => item.name);
    setSelectedExamTypes(val);
    setSelectedExamTypesName(nameList);
  };

  return (
    <Box component="div" className='body-cont-over'>
      <Menu />

      <Backdrop open={isLoading} className="quiz-loader">
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={snackbarOpen} onClose={handleSnackbarClose} autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <>
          {createPlanRes?.plan?.id &&
            <Box className="success-alert">
              Plan has been created successfully...
            </Box>
          }
          {updatePlanRes?.plan?.id &&
            <Box className="success-alert">
              Plan has been updated successfully...
            </Box>
          }
          {getDeletePlanRes?.plan?.id &&
            <Box className="success-alert">
              Plan has been deleted successfully...
            </Box>
          }
          <Box>   </Box>
          {getDeletePlanRes?.plan?.errMsg &&
            <Box className="sub-alert">
              {getDeletePlanRes?.plan?.errMsg?.message}
            </Box>
          }
          {createPlanRes?.plan?.errMsg &&
            <Box className="sub-alert">
              {createPlanRes?.plan?.errMsg?.message}
            </Box>
          }
          {updatePlanRes?.plan?.errMsg &&
            <Box className="success-alert">
              {updatePlanRes?.plan?.errMsg?.message}
            </Box>
          }
          {sendInvitesRes?.sendInvites?.id &&
            <Box className="success-alert">
              Invite has been sent successfully...
            </Box>
          }
        </>
      </Snackbar>

      <Box className='admin-plan-container'>
        <Box className="btns-cont">
          <Button className="marg-right" variant="contained" color="primary"
            onClick={() => setInviteModal(true)}>
            Send Invites
          </Button>

          <Button className="plan-create-btn" variant="contained" color="primary"
            onClick={openCreateModal}>
            Create Plan
          </Button>
        </Box>

        <Box className='list-container'>
          <Typography variant="h5" className="header">Available Plans:</Typography>
          <Paper elevation={1}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>CATEGORY</TableCell>
                    <TableCell className="max-width-row">EXAM TYPE</TableCell>
                    <TableCell>PRICE</TableCell>
                    <TableCell>DURATION</TableCell>
                    <TableCell>Q'S PER DAY</TableCell>
                    <TableCell>QUIZ TYPE</TableCell>
                    <TableCell className="max-width-row">DESCRIPTION</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {planList?.length > 0 && planList?.sort((a, b) => a.price - b.price)?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {row.name}
                      </TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell className="max-width-row">{row?.examTypes?.join(", ", " ")}</TableCell>
                      <TableCell>${row.price}</TableCell>
                      <TableCell>{CONSTANTS.durationObj[row.durationInDay] || row.durationInDay}</TableCell>
                      <TableCell>{row.questionsPerDay}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell className="max-width-row">{row.description}</TableCell>
                      <TableCell>
                        <IconButton className="blue" onClick={() => editPlan(row)}>
                          <Edit />
                        </IconButton>
                        {row.category !== 'FREE' &&
                          <IconButton className="red" onClick={() => onDeletePlan(row)}>
                            <Delete />
                          </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                  {planList?.length === 0 &&
                    <tr>
                      <td className="no-data" colSpan={8}>
                        No data found
                      </td>
                    </tr>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>

      <Modal
        className='delete-plan-modal'
        open={deletePlanModal}
        onClose={() => setDeletePlanModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={deletePlanModal}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">Are you sure you want to delete this plan?</Typography>
            <Box>
              <Button className="marg-right" variant="contained" color="primary"
                onClick={onDeletePlanConfirm}>
                Yes
              </Button>
              <Button onClick={() => setDeletePlanModal(false)}>
                No
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        className='update-plan-modal'
        open={modal}
        onClose={onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={modal}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">{`${isEdit ? 'Update' : 'Create'} Plan`}</Typography>

            <Box className="plan-edit-form">
              <TextField
                label="Plan Name"
                onChange={(e) => handleChange(e, 'name')}
                value={planForm?.name} />

              <FormControl>
                <InputLabel>Category</InputLabel>
                <Select
                  value={planForm?.category}
                  onChange={(e) => handleChange(e, 'category')}
                >
                  <MenuItem value='FREE'>Free</MenuItem>
                  <MenuItem value='PRO'>Pro</MenuItem>
                  <MenuItem value='MAX'>Max</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>Exam Type</InputLabel>
                <Select
                  multiple
                  value={selectedExamTypes}
                  onChange={examTypeMultipleSelect}
                  input={<Input />}
                  renderValue={() => selectedExamTypesName.join(', ')}
                >
                  {CONSTANTS.examTypeList.map((typeObj) => (
                    <MenuItem key={typeObj.value} value={typeObj}>
                      <Checkbox checked={selectedExamTypesName.indexOf(typeObj.name) > -1} />
                      <ListItemText primary={typeObj.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField label="Price"
                type="number"
                value={planForm?.price}
                onChange={(e) => handleChange(e, 'price', true)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }} />

              <FormControl>
                <InputLabel>Duration</InputLabel>
                <Select
                  value={planForm?.durationInDay}
                  onChange={(e) => handleChange(e, 'durationInDay')}
                >
                  <MenuItem value={7}>{CONSTANTS.durationObj['7']}</MenuItem>
                  <MenuItem value={30}>{CONSTANTS.durationObj['30']}</MenuItem>
                  <MenuItem value={90}>{CONSTANTS.durationObj['90']}</MenuItem>
                  <MenuItem value={180}>{CONSTANTS.durationObj['180']}</MenuItem>
                </Select>
              </FormControl>

              <TextField label="Question Per Day"
                type="number"
                value={planForm?.questionsPerDay}
                onChange={(e) => handleChange(e, 'questionsPerDay', true)} />

              <FormControl>
                <InputLabel>Type</InputLabel>
                <Select
                  value={planForm?.type}
                  onChange={(e) => handleChange(e, 'type')}
                >
                  <MenuItem value='GENERAL'>General</MenuItem>
                  <MenuItem value='UPGRADED'>Upgraded</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Description" multiline={true} maxRows={4}
                onChange={(e) => handleChange(e, 'description')}
                value={planForm?.description} />
            </Box>

            <Button className="create-btn marg-right" variant="contained" color="primary"
              onClick={onCreatePlan} disabled={isSubmitDisabled()}>
              {`${isEdit ? 'Update' : 'Create'}`}
            </Button>
            <Button className="create-btn"
              onClick={onModalClose}>
              Cancel
            </Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        className='send-invites-modal'
        open={inviteModal}
        onClose={() => setInviteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={inviteModal}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">Send Invites</Typography>

            <Box className="plan-edit-form">
              <FormControl>
                <InputLabel>Plan Name</InputLabel>
                <Select
                  value={inviteForm?.planId}
                  onChange={(e) => handleInvitesChange(e, 'planId')}
                >
                  {planList?.filter(item => item.category !== 'FREE')?.map(item =>
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  )}
                </Select>
              </FormControl>

              <TextField label="Email"
                type="text"
                value={inviteForm?.email}
                onChange={(e) => handleInvitesChange(e, 'email')} />
            </Box>

            <Button className="create-btn marg-right"
              onClick={onInvitesModalClose}>
              Cancel
            </Button>
            <Button className="create-btn" variant="contained" color="primary"
              onClick={onSendInvites} disabled={isInvitesSubmitDisabled()}>
              Send
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
